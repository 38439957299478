#page-edit .block-editor-gridBlock .block.gridBlock,
#page-add .block-editor-gridBlock .block.gridBlock,
#page-edit {
  .teaser.teaser--empty-edit {
    height: 200px;
    border-radius: 4px;
    background: rgba(#d8e3e6, 0.2);
  }
}

#page-edit .block-editor-gridBlock .block.gridBlock,
#page-add .block-editor-gridBlock .block.gridBlock,
.block.gridBlock,
#page-edit .block.listing,
#page-add .block.listing,
.block.listing,
.block.search {
  .teaser,
  .block.teaser {
    background-color: transparent;
  }

  .teaser {
    display: flex;
    width: 100%;
    flex: 1;

    .teaser__outline-spacer {
      flex: 1;
      margin-bottom: -16px;
    }

    .teaser-item {
      flex-direction: column;
      padding-bottom: calc(2 * var(--column-gap));
      border-bottom: none;
    }

    .teaser-item .image-wrapper {
      margin: 0;
      margin-bottom: 28px;
    }

    .headline {
      margin: 0;
      letter-spacing: 0.5px;
    }

    .teaser-item {
      flex: 1;
      align-items: stretch;
      hyphens: auto;
      overflow-wrap: anywhere;

      .teaser__content {
        display: flex;
        flex: 1;
        flex-direction: column;
        padding: 0 12px;
        gap: 16px;

        p {
          line-height: 1.5;
        }

        .teaser__links {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 36px;

          .teaser__read-more {
            margin-top: 0;
          }

          .button {
            margin: 0;
          }
        }

        .teaser__related-links {
          display: flex;
          flex: 1;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-end;
          margin-top: 42px;

          a {
            display: block;
            border-bottom: 1px rgba(255, 255, 255, 0.3) solid;

            &:not(:last-child) {
              margin-bottom: 12px;
            }
          }
        }

        .news-date-tags {
          display: flex;
          gap: 8px;

          .teaser__created-at {
            padding: 7px 0;
          }
        }
      }
    }

    &.cta-teaser {
      .teaser__content {
        gap: 24px;
      }

      .teaser__content .headline {
        font-size: 34px !important;
      }

      .teaser__title-underline {
        width: 160px;
        height: 1px;
        background: rgba(#d8e3e6, 0.7);
      }

      .button {
        align-self: flex-start;
        margin-top: 16px;
      }
    }

    &.card-teaser,
    &.cardTeaser,
    &.data-teaser {
      .teaser__content {
        width: 100%;
        padding: 0;
      }

      .headline {
        display: flex;
        justify-content: space-between;
        padding-bottom: 16px;
        border-bottom: 1px solid rgba(#d8e3e6, 0.7);
        margin-bottom: 0;

        svg {
          width: 24px !important;
          height: 24px !important;
          flex-shrink: 0;
          border-radius: 999px;
          margin-top: 4px;
          background: rgba($secondary-color, 0.1);
          color: $secondary-color;
        }
      }

      &.teaser--solid {
        .teaser__content {
          padding: 24px;
          border: 1px solid rgba(#d8e3e6, 0.7);
          background: #f8fbff;

          h3 {
            svg {
              background: white;
            }
          }
        }
      }
    }

    &.news-teaser {
      .teaser-item .teaser__content {
        padding: 0 32px;
        gap: 8px;

        p {
          margin: 0;
        }
      }
    }

    &.event-teaser {
      .teaser-item {
        display: flex;
        flex-direction: row;

        .image-wrapper {
          width: 50%;
        }

        .teaser__content {
          margin-left: 12px;
        }

        .headline {
          font-size: 21px;
        }

        .teaser__created-at {
          padding-bottom: 4px;
          border-bottom: 1px solid rgba(12, 53, 67, 0.1);
          margin-top: 0;
          margin-bottom: 4px;
        }
      }
    }

    &.publication-teaser {
      &.publication-teaser--horizontal {
        .teaser-item {
          flex-direction: row;
          gap: 40px;
        }
      }

      .image-wrapper {
        width: 220px;
        height: 300px;
        margin: 0;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .headline {
        font-size: 20px;
        line-height: 1.2;
      }

      .teaser__content {
        padding: 0;
        margin-top: 30px;
        margin-bottom: 30px;
      }
    }
    @media only screen and (min-width: $tablet-breakpoint) {
      .teaser__content {
        padding: 0 36px;
      }
    }

    &.spotlight-teaser {
      .teaser__content {
        padding: 0 32px;
      }

      .headline {
        display: flex;
        align-items: center;
        text-transform: uppercase;

        img {
          border-radius: 999px;
          margin-left: 16px;
          filter: inverse(1);

          &.teaser__headline-chevron--dark {
            display: flex;
            width: 20px;
            height: 20px;
            flex-shrink: 0;
            padding: 4px;
            background: rgba($primary-color, 0.07);
          }
        }
      }

      p {
        opacity: 0.8;
      }
    }

    &.minimal-teaser {
      .typography--h3 {
        font-size: 20px;
      }
    }
  }
}
@import './StandaloneTeaser';
