.publication-teaser {
  padding: 40px;
  background-color: #f8fbff;

  .publication-image {
    width: 100%;
  }

  button {
    margin: auto;
    margin-top: 20px;

    span {
      display: flex;
      align-items: center;
      column-gap: 15px;
    }
  }
}
