#page-document,
#page-edit,
#page-add {
  &,
  .search.block {
    .items {
      .listing-item {
        border-color: rgba($primary-color, 0.1) !important;

        .listing-body {
          display: flex;
          align-items: center;
          gap: 12px;

          h2,
          h3 {
            margin: 0;
            color: $primary-color;
            font-size: 26px;
          }

          p {
            color: $primary-color;
            font-size: 18px;
            line-height: 1.7;
          }
        }
      }
    }

    .ui.grid {
      margin: 0 -10px;
    }

    .listing-items {
      .listing-item {
        .listing-body {
          display: flex;
          flex-direction: column;
          gap: 16px;

          p,
          h3 {
            margin: 0;
            color: $primary-color;
          }

          h3 {
            font-size: 26px;
            font-weight: 500;
          }

          .tags {
            margin: 0;
          }
        }

        border: none;

        &.listing-item--event {
          .listing-body {
            flex-direction: row;

            img {
              width: 300px;
              height: 200px;
              flex-shrink: 0;
              object-fit: cover;
            }

            .listing-body__content {
              display: flex;
              flex-direction: column;
              gap: 8px;

              .listing-body__date {
                padding-bottom: 12px;
                border-bottom: rgba(#0c3543, 0.1) solid 1px;

                b {
                  font-weight: 500;
                }
              }
            }
          }
        }

        &.listing-item--publication {
          .listing-body {
            flex-direction: row;
            align-items: center;

            > img {
              display: block;
              width: 218px;
              height: 297px;
              flex-shrink: 0;
              object-fit: cover;
            }

            .listing-body__content {
              display: flex;
              flex-direction: column;
              gap: 8px;

              .listing-body__ruler {
                height: 1px;
                margin: 16px 0;
                background: rgba($primary-color, 0.1);
              }

              .listing-body__actions {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                gap: 24px;

                button,
                a {
                  width: auto;
                  flex-shrink: 0;
                  align-self: unset;
                  margin-top: 0;
                  word-break: normal;

                  span {
                    word-break: normal;
                  }
                }
              }
            }

            .download-publication span {
              width: 100%;
            }
          }
        }

        &.listing-item--catalogus,
        &.listing-item--catalogus:last-child {
          padding-bottom: 32px !important;
          border-bottom: 1px solid rgba(#0c3543, 0.1) !important;
          margin-bottom: 32px;
        }
      }
    }

    .vmm-article-listing {
      .vmm-listing__items {
        display: flex;

        img {
          width: 100%;
          height: 280px;
          flex-grow: 0;
          object-fit: cover;
        }

        .vmm-listing__content {
          padding: 24px 20px;

          h3 {
            margin-bottom: 12px;
            line-height: 1.2;
          }

          .vmm-listing__item.column {
            padding: 20px;

            img {
              width: 100%;
              height: 280px;
              flex-grow: 0;
              flex-shrink: 0;
              object-fit: cover;
            }

            .vmm-listing__content {
              padding: 24px 20px;

              h3 {
                margin-bottom: 12px;
                line-height: 1.2;
              }

              p {
                margin: 0;
              }
            }

            @media only screen and (min-width: $tablet-breakpoint) and (max-width: $computer-breakpoint) {
              width: 100% !important;
              flex-direction: row;

              img {
                width: 185px;
                height: 150px;
              }

              .vmm-listing__content {
                padding-top: 0;
                padding-bottom: 0;
              }
            }
          }
        }
      }
    }
  }

  .block.listing {
    .block-header:has(> a:nth-child(1):nth-last-child(1)) > a {
      display: block;
      width: 100%;
      text-align: right;

      &:after {
        content: none !important;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .listing-item.listing-item--event .listing-body img {
    width: 210px !important;
    height: 140px !important;
  }
}

@media screen and (max-width: 768px) {
  .listing-item.listing-item--publication {
    margin-bottom: 0 !important;

    .tags {
      padding: 15px 0;
    }

    .listing-body img {
      width: 40% !important;
      height: auto !important;
    }

    .listing-body__content p,
    .listing-body__ruler,
    .listing-body__actions span {
      display: none;
    }

    .listing-body__actions {
      flex-direction: row-reverse;
      align-self: flex-start;
      justify-content: flex-end;

      .read-more-link {
        flex-direction: row;
      }

      button img {
        width: 35px;
        height: 35px;
        border-radius: 100%;
        background: rgba(255, 255, 255, 0.1);
        object-fit: none;
      }
    }
  }

  .listing-item.listing-item--event .listing-body__content p {
    display: none;
  }
}

@media screen and (max-width: 640px) {
  .listing-item.listing-item--publication .listing-body {
    flex-direction: column !important;
    align-items: start !important;
    padding-bottom: 60px;
    border-bottom: 1px solid rgba($primary-color, 0.1); //#0c35431a;
    margin-bottom: 30px;

    img {
      width: auto !important;
      height: auto !important;
    }
  }

  .listing-items .listing-item.listing-item--event .listing-body {
    flex-direction: column !important;
    padding-bottom: 40px;
    border-bottom: 1px solid rgba($primary-color, 0.1);
  }
}

@media screen and (min-width: 1200px) {
  .section-nieuws .ui.four.column.grid .column.vmm-listing__item:first-of-type {
    width: 50%;

    a.teaser-item {
      position: relative;
      overflow: hidden;

      .image-wrapper {
        position: relative;

        img {
          aspect-ratio: 4 / 3 !important;
        }

        &:after {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(0deg, #0c3543 0%, transparent 50%);
          content: '';
        }
      }

      .teaser__content {
        position: absolute;
        justify-content: flex-end;
        padding-bottom: 25px;
        aspect-ratio: 4 / 3;

        > h3,
        p {
          color: white;
        }
      }
    }
  }
}
@media screen and (max-width: 1199px) {
  .ui.four.column.grid .column.vmm-listing__item {
    width: 33.33333333%;
  }
}
@media screen and (max-width: 939px) {
  .ui.three.column.grid .column.vmm-listing__item,
  .ui.four.column.grid .column.vmm-listing__item {
    width: 50%;
  }
}

#page-add,
#page-edit,
#page-document {
  .blocks-group-wrapper.transparent {
  }

  .blocks-group-wrapper.light,
  .has--backgroundColor--light:not([role='presentation']) {
    .block.listing {
      .image-gallery {
        .image-gallery-left-nav,
        .image-gallery-right-nav,
        .image-gallery-play-button,
        .image-gallery-icon {
          .image-gallery-svg {
            fill: none !important;
          }
        }
      }
    }
  }

  .blocks-group-wrapper.dark {
    .block.listing {
      .image-gallery {
        .image-gallery-description {
          color: $primary-color;
        }
      }
    }
  }

  .block.listing {
    .image-gallery {
      .image-gallery-content .image-gallery-slide .image-gallery-image {
        max-height: calc(100vh - 150px);
      }

      .image-gallery-icon {
        color: $primary-color;
        filter: none;
        //&:hover {
        //background-color: $secondary-color;
        //background-color: rgba($primary-color, 0.2);
        //border-radius: 500px;
        //}
      }

      .image-gallery-thumbnails {
        padding: 15px 0;

        .image-gallery-thumbnail {
          transition: 0.25s;

          &:hover,
          &.active {
            box-shadow: 0 0 0px 7px $secondary-color;
          }
        }
      }

      .image-gallery-description {
        position: absolute;
        bottom: 15px;
        left: 50%;
        padding: 16px;
        border-radius: 15px;
        margin: 0 30px;
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        background: rgba(2, 19, 21, 0.35);
        color: white !important;
        font-size: 1rem;
        line-height: 1.5;
        transform: translateX(calc(-50% - 30px));
      }

      .image-gallery-left-nav,
      .image-gallery-right-nav {
        padding: 0;

        &[disabled] {
          display: none;
        }

        .image-gallery-svg {
          height: 60px;
          border-radius: 15px;
          -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
          background: rgba($primary-color, 0.1);
          transition: 0.25s;
        }
      }

      .image-gallery-play-button,
      .image-gallery-fullscreen-button {
        border-radius: 15px;
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        background: rgba($primary-color, 0.1);

        &:hover {
          transform: scale(1.1);
        }
      }

      .image-gallery-svg {
        stroke-linecap: square;
        stroke-linejoin: unset;
        stroke-width: 2;
      }

      .image-gallery-index {
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        background: rgba(2, 19, 21, 0.35);

        * {
          color: white !important;
        }
      }

      .image-gallery-content:not(.fullscreen)
        img.image-gallery-image[src*='image-not-found'] {
        max-height: 300px !important;
      }

      .image-gallery-bullets {
        position: unset;
        bottom: -30px;
        margin: 20px auto 30px auto;

        .image-gallery-bullets-container {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          gap: 8px;
        }

        .image-gallery-bullet {
          display: block;
          width: 14px;
          height: 9px;
          padding: unset;
          border-radius: 99px;
          margin: unset;
          background-color: $primary-color;
          box-shadow: none;
          opacity: 0.1;
          transition:
            width 0.2s ease,
            opacity 0.2s ease;

          &.active {
            width: 25px;
            background: $primary-color;
            opacity: 1;
            transform: unset;
          }

          &:hover,
          &.active:hover {
            border-color: white;
            background-color: $primary-color;
            transition: unset;
          }
        }
      }

      .fullscreen {
        svg.image-gallery-svg {
          stroke: white;
        }

        .image-gallery-bullets .image-gallery-bullet {
          background-color: white;
          opacity: 0.3;

          &.active {
            opacity: 1;
          }
        }
      }
    }
  }

  .blocks-group-wrapper.dark,
  .has--backgroundColor--dark:not([role='presentation']) {
    .image-gallery {
      .image-gallery-bullets {
        .image-gallery-bullet {
          border-color: $primary-color;
          background: white;
          opacity: 0.3;

          &:hover,
          &.active,
          &.active:hover {
            background: white;
          }

          &.active {
            opacity: 1;
          }
        }
      }
    }
  }
}
